<template>
    <button
        :disabled="$attrs.disabled || loading"
        :type="type"
        class="group relative inline-block h-12 w-full bg-blueGray-900 disabled:bg-gray-500"
    >
        <div
            class="absolute top-0 left-0 transform -translate-y-1 -translate-x-1 w-full h-full group-hover:translate-y-0 group-hover:translate-x-0 transition duration-300 group-disabled:-translate-x-1 group-disabled:-translate-y-1">
            <div
                class="flex h-full w-full items-center justify-center border-2 border-black transition duration-300 group-disabled:bg-gray-400"
                :class="[
                    active ? 'bg-indigo-700' : '',
                    colors[color],
                    `hover:${colors[hoverColor]}`
                ]"
            >
                <span class="text-base font-black" :class="`text-${textColor}`">
                    <Loader v-if="loading" class="h-6 w-6 text-white"/>

                    <slot v-else/>
                </span>
            </div>
        </div>
    </button>
</template>

<script>
import Loader from "@/Components/Svg/Loader.vue";

export default {
    components: {Loader},
    props: {
        type: {
            type: String,
            default: 'submit',
        },
        active: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: 'black'
        },
        hoverColor: {
            type: String,
            default: 'indigo'
        },
        textColor: {
            type: String,
            default: 'white'
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            colors: {
                black: 'bg-black',
                indigo: 'bg-indigo-500',
                green: 'bg-green-600',
                white: 'bg-white',
            },
        }
    }
}
</script>
